<template>
  <div>
    <!----------------If program found ------------------>

    <v-container
      fluid
      tile
      class="pa-0"
      :style="'background-color: ' + this.webTheme.colors.canvas.hexa"
    >
      <v-row justify="center" no-gutters>
        <v-col cols="12" :sm="component.settings.cols">
          <v-card
            elevation="0"
            :height="height"
            tile
            :color="component.settings.color.hexa"
            :img="
              component.settings.mode === 'image'
                ? component.background.image
                : ''
            "
          >
            <v-row
              no-gutters
              :justify="component.block.justify"
              :align="component.block.align"
              class="fill-height"
            >
              <v-col xs="12" sm="10" md="8" :lg="component.block.cols">
                <v-col>
                  <v-card
                    :class="classes"
                    :style="styles + ';' + gradient"
                    :height="component.card.height"
                    :elevation="component.card.elevation"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="auto"
                        v-if="
                          component.logo.placement === 'left' &&
                            component.logo.image
                        "
                      >
                        <view-logo
                          :style="logoMargin"
                          :settings="component.logo"
                        />
                      </v-col>
                      <v-col>
                        <view-logo
                          :style="logoMargin"
                          v-if="
                            component.logo.placement === 'top' &&
                              component.logo.image
                          "
                          :settings="component.logo"
                        />

                        <template v-if="!forgotSuccess">
                          <div>
                            <div v-if="!forgot">
                              <view-text
                                :settings="component.title"
                              ></view-text>
                              <view-text
                                :settings="component.subtitle"
                              ></view-text>

                              <v-form
                                @submit.prevent="onSignin"
                                v-model="valid"
                              >
                                <v-text-field
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  v-model="email"
                                  name="Email"
                                  :label="$t('Email')"
                                  type="email"
                                  :rules="emailRules"
                                  required
                                />

                                <v-text-field
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  v-model="password"
                                  name="password"
                                  :label="$t('Password')"
                                  type="password"
                                  :rules="passwordRules"
                                  required
                                />

                                <v-row
                                  no-gutters
                                  :justify="component.button.justify"
                                >
                                  <v-btn
                                    v-if="currentProgram.signinOpen"
                                    type="submit"
                                    :color="component.button.color.hexa"
                                    :block="component.button.block"
                                    :dark="!component.button.dark"
                                    class="ma-0"
                                    elevation="0"
                                    :width="component.button.width"
                                    :height="component.button.height"
                                    :loading="loading"
                                  >
                                    {{ $t("Sign In") }}
                                  </v-btn>
                                  <v-btn
                                    v-else
                                    type="submit"
                                    :color="component.button.color.hexa"
                                    :block="component.button.block"
                                    disabled
                                    class="ma-0"
                                    elevation="0"
                                    :width="component.button.width"
                                    :height="component.button.height"
                                    :loading="loading"
                                  >
                                    {{ $t("Closed") }}
                                  </v-btn>
                                </v-row>
                              </v-form>

                              <!-- forgot password -->
                              <a v-if="signinOpen" @click="forgotPassword()">
                                <view-text
                                  :settings="component.forgot"
                                  :value="component.value"
                                ></view-text>
                              </a>
                              <view-text
                                v-else
                                :settings="component.forgot"
                                :value="component.value"
                              ></view-text>

                              <!-- sign up -->
                              <template v-if="registrationOpen">
                                <div>
                                  <view-text
                                    :settings="component.account"
                                    :value="component.value"
                                  ></view-text>

                                  <a @click="signUp()">
                                    <view-text
                                      :settings="component.signup"
                                      :value="component.value"
                                    ></view-text>
                                  </a>
                                </div>
                              </template>

                              <!-- agree to terms -->
                              <view-text
                                :settings="component.signing"
                                :value="component.value"
                              ></view-text>

                              <a @click="handleTermsDialog()">
                                <view-text
                                  :settings="component.terms"
                                  :value="component.value"
                                ></view-text>
                              </a>
                            </div>

                            <div v-else>
                              <view-text
                                :settings="component.title"
                                value="Forgot Password"
                              ></view-text>
                              <view-text
                                :settings="component.signing"
                                value="Enter your email below"
                              ></view-text>
                              <view-text
                                :settings="component.signing"
                                value="A password reset link will be sent to you by email"
                              ></view-text>

                              <v-form
                                @submit.prevent="onResetPassword"
                                v-model="valid"
                              >
                                <v-text-field
                                  class="mt-6"
                                  :outlined="true"
                                  :dense="true"
                                  background-color="white"
                                  v-model="email"
                                  name="Email"
                                  :label="$t('Email')"
                                  type="email"
                                  :rules="emailRules"
                                  required
                                />

                                <v-row
                                  no-gutters
                                  :justify="component.button.justify"
                                >
                                  <v-btn
                                    type="submit"
                                    :color="component.button.color.hex"
                                    :block="component.button.block"
                                    :dark="!component.button.dark"
                                    class="ma-0"
                                    elevation="0"
                                    :width="component.button.width"
                                    :height="component.button.height"
                                    :disabled="
                                      currentProgram.signinOpen === false
                                    "
                                    :loading="loading"
                                  >
                                    {{ $t("Send reset link by email") }}
                                  </v-btn>
                                </v-row>
                              </v-form>

                              <a @click="backToSignIn()">
                                <view-text
                                  :settings="component.forgot"
                                  value="Back to Sign In"
                                ></view-text>
                              </a>
                            </div>
                          </div>
                        </template>

                        <template v-else>
                          <div>
                            <view-text
                              :settings="component.title"
                              value="Forgot Password"
                            ></view-text>
                            <view-text
                              :settings="component.subtitle"
                            ></view-text>
                            <v-row
                              no-gutters
                              class="px-8 pb-9"
                              justify="center"
                            >
                              <v-icon
                                size="100"
                                class="pb-12 mb-0 pt-6"
                                :color="
                                  this.$store.getters.currentProgram.webTheme
                                    .colors.primary.hex
                                "
                                >fa-regular fa-circle-check</v-icon
                              >
                              <view-text
                                :settings="component.signing"
                                :value="
                                  'Your request has been submitted and a password reset link is being sent to your email inbox'
                                "
                              ></view-text>
                              <a @click="backToSignIn()" class="mt-9">
                                <view-text
                                  :settings="component.forgot"
                                  value="Back to Sign In"
                                ></view-text>
                              </a>
                            </v-row>
                          </div>
                        </template>

                        <view-logo
                          :style="logoMargin"
                          v-if="
                            component.logo.placement === 'bottom' &&
                              component.logo.image
                          "
                          :settings="component.logo"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-if="
                          component.logo.placement === 'right' &&
                            component.logo.image
                        "
                      >
                        <view-logo
                          :style="logoMargin"
                          :settings="component.logo"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <Terms
          :termsDialogOpen="openTermsDialog"
          @onClose="onCloseTerms"
          :component="component"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ViewLogo from "../Logo.vue";
import ViewText from "../Text.vue";
import Footnote from "../../../Footnote.vue";
import Terms from "./TermsDialog.vue";
import Axios from "axios";
import { apiDomain } from "@/config";
export default {
  components: {
    ViewLogo,
    ViewText,
    Footnote,
    Terms
  },
  async created() {
    this.$store.dispatch("setSnackbar", null);
    this.$store.dispatch("setCurrentUser", null);
    this.$store.dispatch("setAuthLoading", false);
  },
  data() {
    return {
      termsText: "By signing in, you agree to the",
      termsLink: "Terms and Conditions",
      forgotText: "Forgot Password",
      userDealer: [],
      adminToken: null,
      email: "",
      forgotSuccess: false,
      token: null,
      errorMessages: "",
      valid: true,
      password: "",
      message: "",
      forgot: false,
      openTermsDialog: false
    };
  },
  props: ["component", "index"],
  async created() {
    this.$store.dispatch("setCurrentUser", null);
  },
  computed: {
    emailRules() {
      const arr = [
        v => !!v || this.$t("Email is required"),
        v => /.+@.+\..+/.test(v) || this.$t("Not a valid email")
      ];
      return arr;
    },
    passwordRules() {
      const arr = [v => !!v || this.$t("Password is required")];
      return arr;
    },
    loading() {
      return this.$store.getters.loading;
    },
    programId() {
      return this.$store.getters.programId;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
    webTheme() {
      if (this.currentProgram) {
        return this.$store.getters.currentProgram.webTheme;
      }
      return {};
    },
    height() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + "px";
      } else {
        return this.component.heightValue.vh + "vh";
      }
    },
    registrationOpen() {
      return this.$store.getters.currentProgram.registrationOpen || false;
    },
    signinOpen() {
      return this.$store.getters.currentProgram.signinOpen || false;
    },
    domainError() {
      return this.$store.getters.domainError;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    loading() {
      return this.$store.getters.authLoading;
    },
    error() {
      return this.$store.getters.authError;
    },
    gradient() {
      if (this.component.card.color.type === "gradient") {
        var color = this.component.card.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return "background: " + this.component.card.color.solidColor.hexa + ";";
      }
    },
    styles() {
      var styles =
        "margin-bottom: " +
        this.component.card.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.card.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.card.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.card.margin.right +
        "px ;" +
        "padding-bottom: " +
        this.component.card.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.card.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.card.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.card.padding.right +
        "px ;" +
        "border: " +
        this.component.card.borderSize +
        "px solid " +
        this.component.card.borderColor.hexa +
        "; ";
      return styles;
    },
    classes() {
      var classes = this.component.card.rounded;
      return classes;
    },
    grid() {
      return this.$store.getters.grid;
    },
    logoMargin() {
      const val =
        "margin-bottom: " +
        this.component.logo.marginBottom +
        "px ;" +
        "margin-top: " +
        this.component.logo.marginTop +
        "px ;" +
        "margin-left: " +
        this.component.logo.marginLeft +
        "px ;" +
        "margin-right: " +
        this.component.logo.marginRight +
        "px ;";
      return val;
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates;
    },
    height() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + "px";
      } else {
        return this.component.heightValue.vh + "vh";
      }
    },
    imageHeight() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + this.component.overlap.px + "px";
      } else {
        return this.component.heightValue.vh + this.component.overlap.vh + "vh";
      }
    },
    image() {
      if (this.component.settings.mode === "color") {
        return "";
      } else {
        return this.component.settings.image;
      }
    }
  },
  methods: {
    reset() {
      this.$store.dispatch("setFirstTimeLoad", true);
    },
    translate(val) {
      return this.$t(val);
    },
    async onSignin() {
      this.$store.dispatch("setAuthLoading", true);
      await this.$store.dispatch("signUserInFirebase", {
        email: this.email,
        password: this.password
      });
    },
    signUp() {
      this.$store.dispatch("setAuthLoading", false);
      this.$router.push("/signup");
      this.$store.dispatch("setSelectedPageTemplate", "Signup");
    },
    onResetPassword() {
      this.$store.dispatch("setAuthLoading", true);
      Axios.post(apiDomain + "/auth/reset-password-request", {
        email: this.email,
        programId: this.programId
      })
        .then(() => {
          this.$store.dispatch("setAuthLoading", false);
          this.email = "";
          this.$store.dispatch(
            "setSnackbar",
            "Password reset email sent. Please check your inbox"
          );
          this.forgotSuccess = true;
        })
        .catch(err => {
          this.$store.dispatch("setAuthLoading", false);
          this.$store.dispatch("setSnackbar", err.response.data);
          this.forgotSuccess = false;
        });
    },
    forgotPassword() {
      this.$store.dispatch("setAuthLoading", false);
      this.forgot = true;
    },
    backToSignIn() {
      this.$store.dispatch("setAuthLoading", false);
      this.forgot = false;
      this.forgotSuccess = false;
    },
    handleTermsDialog() {
      this.openTermsDialog = true;
    },
    onCloseTerms() {
      this.openTermsDialog = false;
    }
  },
  watch: {
    currentUser(value) {
      if (value) {
        this.$store.dispatch("loadTranslationData");
        this.$store.dispatch("getPrimaryMenus", this.currentUser);
        this.$store.dispatch("getSecondaryMenus", this.currentUser);
        this.$store.dispatch("getUserDealer", this.currentUser);
        this.$store.dispatch("getProgressData", this.currentUser);
        this.$router.push("/Home");
        this.$store.dispatch("getPromotionList", this.currentUser);
        this.$store.dispatch("getRewardList", this.currentUser);
        this.$store.dispatch("getOfferList", this.currentUser);
      }
    }
  }
};
</script>

<style scoped>
.sticky-footer {
  position: fixed;
  background: transparent;
  bottom: 0px;
  left: 290px;
  margin-bottom: 10px;
  z-index: 1000 !important;
}
.sub-head-auth-page {
  font-size: 14px !important;
  font-weight: 300 !important;
  color: white !important;
}
.heading-auth-page {
  font-size: 24px !important;
  font-weight: 200 !important;
  color: white !important;
}
</style>
