<template>
  <div :style="'background-color: ' + webTheme.colors.canvas.hexa">
    <component
      :is="component.id"
      v-for="component in authentication"
      :key="component.idx"
      :component="component"
    ></component>
    <v-row no-gutters justify="center" v-if="authentication.length === 0">
      <v-col>
        <v-card class="pa-3 grey lighten-4" elevation="0">
          <v-card class="mt-3 mx-3 pa-6 dotted" elevation="0" height="100vh">
            <v-row no-gutters justify="center" class="page-heading mb-3">
              Authentication
            </v-row>
            <v-row no-gutters justify="center" class="page-subheading mb-3">
              There are no active authentication components.
            </v-row>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AuthSignIn from "./ViewComponents/Shared/Authentication/SignIn.vue";
import HeadingSection from "./ViewComponents/Shared/HeadingSection.vue";
import Banner from "./ViewComponents/Header/Banner.vue";
import ImageComponent from "./ViewComponents/Shared/Image.vue";
import TextOneCol from "./ViewComponents/Shared/TextOneCol.vue";
import TextTwoCol from "./ViewComponents/Shared/TextTwoCol.vue";
import TextThreeCol from "./ViewComponents/Shared/TextThreeCol.vue";

export default {
  components: {
    AuthSignIn,
    HeadingSection,
    Banner,
    ImageComponent,
    TextOneCol,
    TextTwoCol,
    TextThreeCol
  },
  created() {},
  data: () => ({}),
  computed: {
    grid() {
      return this.$store.getters.grid;
    },
    programId() {
      return this.$store.getters.programId;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    selectedPageTemplate() {
      return this.$store.getters.selectedPageTemplate;
    },
    designDrawer() {
      return this.$store.getters.designDrawer;
    },
    authentication() {
      return this.webTheme.auth.signin.components;
    },
    header() {
      return this.webTheme.header.components;
    },
    // Member site specific models
    currentUser() {
      return this.$store.getters.currentUser;
    }
  }
};
</script>

<style scoped>
.dotted {
  border: 1px dashed rgb(184, 184, 184) !important;
}
</style>
